import * as React from "react"
import { PageProps, graphql, Link } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Box from "../../../components/box"
import Text from "../../../components/text"
import { ArrowLeftIcon } from "../../../components/icons"
import Divider from "../../../components/divider"
import { useRef } from "react"
import { styled } from "@stitches/react"
import IntroPoint from "../../../components/resume/introPoint"
import { PhotoType } from "../../../components/resume/types"
import Illustration from "../../../components/resume/illustration"

type DataProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  procards: PhotoType
  cardRecomendationExample: PhotoType
  openSupply: PhotoType
  contactPopup: PhotoType
  resultsGraph: PhotoType
  initialState: PhotoType
  cardsExamples: PhotoType
  architecture: PhotoType
  cover: PhotoType
}

const Section = styled("section", {
  padding: "$48 0",
  marginBottom: "0",
  width: "$full",
})

const BackLink = ({
  sidebar = false,
  displayOnMobile = true,
  displayOnDesktop = true,
}: {
  sidebar?: boolean
  displayOnMobile?: boolean
  displayOnDesktop?: boolean
}) => {
  return (
    <Link to="/resume">
      <Box
        flex="row"
        css={{
          alignItems: "center",
          display: displayOnDesktop ? "flex" : "none",
          transform: "translateX(-24px)",
          gap: "$8",
          "#back-icon": {
            transform: "translateX(0px)",
            transition: "$inOut1",
          },

          "&:hover": {
            "#back-icon": {
              transform: "translateX(-16px)",
            },
          },

          "@bp2": {
            display: displayOnMobile ? "flex" : "none",
            transform: "translateX(0)",
          },
        }}
      >
        <ArrowLeftIcon size={16} id="back-icon" />
        <Text
          css={{
            marginBottom: 0,
            fontFamily: "$sans",
            fontSize: sidebar ? "$12" : "$16",
            color: sidebar ? "$text_tertiary" : "$text_primary",
          }}
        >
          Return to resume
        </Text>
      </Box>
    </Link>
  )
}

const SosProjectOverviewPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  const section1 = useRef<HTMLDivElement>(null)
  const section2 = useRef<HTMLLIElement>(null)
  const section3 = useRef<HTMLLIElement>(null)
  const section4 = useRef<HTMLLIElement>(null)
  const section5 = useRef<HTMLLIElement>(null)
  const section6 = useRef<HTMLLIElement>(null)
  const section7 = useRef<HTMLLIElement>(null)
  const section8 = useRef<HTMLLIElement>(null)

  return (
    <Layout title={siteTitle} width="960px">
      <Seo title="StarOfService: Project Overview page" />
      <Box
        ref={section1}
        css={{
          width: "$full",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "$64",

          h2: {
            marginBottom: "$32",
          },
        }}
      >
        <Box
          flex="column"
          css={{
            minHeight: "$fullMinusHeader",
            justifyContent: "end",
            boxSizing: "border-box",
            gap: "$24",
          }}
        >
          <Box
            css={{
              width: "$full",
              borderRadius: "$16",
              overflow: "hidden",
              marginTop: "$16",
            }}
          >
            <Illustration
              image={data.cover}
              alt="Project overview page placed on simplified version of a Customer Journey Map"
            />
          </Box>
          <Box
            grid="1to3"
            css={{
              alignItems: "baseline",
            }}
          >
            <Box></Box>
            <Box
              as="h1"
              flex={"column"}
              css={{
                marginY: "0",
                gap: "$16",
                width: "$full",
                fontSize: "$48",
                lineHeight: "1.2",
              }}
            >
              Project Overview page redesign
              <Text css={{ color: "$text_tertiary" }}>StarOfService, 2023</Text>
            </Box>
          </Box>
          <IntroPoint
            large
            title="Context"
            text="As a part of the Growth Track at StarOfService, I've been working on continuous enhancement of our user experience. The approach can be illustrated with the redesign of the Project Overview page, which is a crucial part of the Client journey — that's where the matching with Professionals happens. During about half a year, user interviews and a number of experiments were held on this page to help clients find the right professional for their request."
          />
          <IntroPoint
            title="My role"
            text="Being responsible for the Growth track alongside our CTO, I was ideating, researching, designing and implementing experiments and gradually redesigning this user journey step."
          />
          <Box>
            <IntroPoint
              title="Goal and results"
              text={`The main goal was to increase clients' engagement with professionals on this step of the user journey. "Request created -> Message to Professional sent" conversion was chosen as the primary metric.`}
            />
            <IntroPoint
              title=""
              text="Over about 6 months of iterations, the share of Clients starting communication with Professionals increased almost 4 times. On the scale of the entire funnel it can be estimated as x2.2 growth."
            />
            <Box
              grid="1to3"
              css={{
                alignItems: "baseline",
              }}
            >
              <Box></Box>
              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$16",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint
                  boxed
                  large
                  title="Target page conversion"
                  text="x4 increase"
                />
                <IntroPoint
                  boxed
                  large
                  title="Entire funnel covertion"
                  text="x2.2 increase"
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          grid="1to3"
          css={{
            width: "$full",
          }}
        >
          <Box
            css={{
              position: "sticky",
              top: "30dvh",
            }}
          >
            <Box
              grid="itemLeft"
              css={{
                display: "flex",
                flexDirection: "column",
                position: "sticky",
                top: "30dvh",

                "@bp2": {
                  display: "none",
                },
              }}
            >
              <BackLink sidebar />
              <Box
                as="ul"
                css={{
                  paddingLeft: 0,
                  li: {
                    listStyleType: "none",
                    fontFamily: "$sans",
                    fontSize: "$12",
                    color: "$text_tertiary",
                    cursor: "pointer",
                    paddingBottom: "$8",

                    "&:hover": {
                      color: "$text_primary",
                    },
                  },
                }}
              >
                <li
                  onClick={() => {
                    if (section1.current) {
                      section1.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Intro
                </li>
                <li
                  onClick={() => {
                    if (section2.current) {
                      section2.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Starting position
                </li>
                <li
                  onClick={() => {
                    if (section3.current) {
                      section3.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Professional cards
                </li>
                <li
                  onClick={() => {
                    if (section4.current) {
                      section4.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Architecture transformations
                </li>
                <li
                  onClick={() => {
                    if (section5.current) {
                      section5.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Open supply
                </li>
                <li
                  onClick={() => {
                    if (section6.current) {
                      section6.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Cut the communication starter efforts
                </li>
                <li
                  onClick={() => {
                    if (section7.current) {
                      section7.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Other
                </li>
                <li
                  onClick={() => {
                    if (section8.current) {
                      section8.current.scrollIntoView({ behavior: "smooth" })
                    }
                  }}
                >
                  Results
                </li>
              </Box>
            </Box>
          </Box>
          <Box>
            <Divider direction="horisontal" />
            <Section ref={section2}>
              <h2>Starting position</h2>
              <Illustration
                image={data.initialState}
                alt="Project overview page placed on simplified version of a Customer Journey Map"
                caption="Project overview page as a part of clients' journey"
              />
              <Text size="large">
                The project overview page was a part of the older model of
                StarOfService and for a long period has been outside the focus,
                while the company was experimenting with alternative business
                models. But in 2023 it once again became a part of the strategic
                view and was migrated to newer infrastructure.
              </Text>
              <Text size="large">
                At that point, page performance was not so impressive and
                required tuning up. StarOfService's Growth team (our CTO and I)
                decided to take it into the scope as a part of the Client user
                journey optimisation initiatives.
              </Text>
            </Section>

            <Divider direction="horisontal" />

            <Section ref={section3}>
              <h2>Professional cards</h2>
              <Box
                flex="column"
                css={{
                  gap: "$16",
                }}
              >
                <Text size="large">
                  This page is where we showcase the professionals based on the
                  client's request. So we wanted to understand what should the
                  "pro card" look like in order to grab clients' attention,
                  build trust and efficiently communicate useful details.
                  Meanwhile, we had some other hypotheses to test, so I
                  initiated a series of user interviews to come prepared for
                  iterating on cards.
                </Text>
                <Illustration
                  image={data.procards}
                  alt="15 miniature pairs of Pro cards"
                  caption="Pro cards variants prepared for user interview"
                />
                <Text size="large">
                  Pro card variants prepared for user interview The process was
                  quite simple - I had prepared 15 pairs of different variations
                  of Pro Cards and then presented them to participants with
                  recent hiring experience to choose the one they'd hire. Each
                  time they were explaining the reasons behind their choice.
                </Text>
                <Text size="large">
                  This discussion was a great starting point to dive into their
                  previous experience and real-life behaviour. Usually, the
                  first 5-6 pairs had been more like a warmup before
                  participants started to recollect the actual reasons behind
                  their recent hirings, telling detailed stories and getting
                  more open and excited about the whole process.
                </Text>
                <Text size="large">
                  So, in the end, we had:
                  <Box
                    css={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      columnGap: "$8",
                      rowGap: "$8",
                      marginY: "$16",

                      "@bp1": {
                        gridTemplateColumns: "1fr",
                      },
                    }}
                  >
                    <IntroPoint
                      boxed
                      outlined
                      reverse
                      text="Matrix of choices based on the card's content and layout"
                    />
                    <IntroPoint
                      boxed
                      outlined
                      reverse
                      text="Matrics of parameters affected the dicisions, mnoticed or neglected"
                    />
                    <IntroPoint
                      boxed
                      outlined
                      reverse
                      text="Witnessed participants' reflections and interactions with
                    the cards' content, patterns of Pro comparisons"
                    />
                    <IntroPoint
                      boxed
                      outlined
                      reverse
                      text="Received the recollections about the participants'
                    previous hiring experience and real-life behaviour and
                    decisions"
                    />
                  </Box>
                  <Illustration
                    image={data.cardRecomendationExample}
                    alt="Recommendations on how to present new StarOfService users"
                    caption="Example of recommendations"
                  />
                </Text>
                <Text size="large">
                  With all that I'd set to cards redesign and after a series of
                  AB tests we've ended up with several variations tailored per
                  type of professional (contacted or not, applying or not,
                  'bookable online', etc).
                </Text>
                <Illustration
                  image={data.cardsExamples}
                  alt="Mobile and desktop versions of Pro cards"
                  caption="Cards for 2 types of professionals"
                />
              </Box>
            </Section>

            <Divider direction="horisontal" />

            <Section ref={section4}>
              <h2>Architecture transformations</h2>
              <Text size="large">
                While having contradicting hypotheses, the CTO and I prepared 2
                variations for an AB test to fight against the baseline:
                <Box
                  flex={"column"}
                  css={{
                    gap: "$16",
                    paddingY: "$24",
                  }}
                >
                  <IntroPoint
                    boxed
                    outlined
                    reverse
                    text="Baseline"
                    title={`Redirect to the "Inbox" highlighting the incoming quotes.`}
                  />
                  <IntroPoint
                    boxed
                    outlined
                    reverse
                    text="CTO's:"
                    title={`A single list of all professionals with more distinctive
                    cards, clearly divided by the level of interaction.`}
                  />
                  <IntroPoint
                    boxed
                    outlined
                    reverse
                    text="Mine"
                    title={`Divide the list into the "Project space" screen for
                    contacted professionals and the "Find professionals" screen
                    for not contacted ones.`}
                  />
                </Box>
              </Text>

              <Text
                css={{
                  li: {
                    marginBottom: "$12",
                    fontSize: "$18",
                  },
                }}
                size="large"
              >
                After observing the results in various scenarios and in the
                context of other updates (which I'm presenting in the next
                sections), we landed on a hybrid solution, which was the
                introduction of 2 tabs:
                <ul>
                  <Text as={"li"}>
                    <Text
                      as={"span"}
                      css={{ fontWeight: "$600" }}
                      size={"large"}
                    >
                      "Inbox":
                    </Text>{" "}
                    clearly divided contacted professionals and professionals,
                    who applied to the request.
                  </Text>
                  <Text as={"li"}>
                    <Text
                      as={"span"}
                      css={{ fontWeight: "$600" }}
                      size={"large"}
                    >
                      "All professionals":
                    </Text>{" "}
                    every other professional, who corresponded to the request.
                  </Text>
                </ul>
              </Text>

              <Illustration
                image={data.architecture}
                alt="Page structure scheme: after request creation, if the client has incoming quotes they appears on Inbox tab, otherwise is redirected to All professional tab."
                caption="Rough final page structure"
              />
              <Text
                css={{
                  li: {
                    marginBottom: "$12",
                    fontSize: "$18",
                  },
                }}
                size="large"
              >
                It helped us to treat different cases in a better manner:
                <ul>
                  <Text as={"li"}>
                    <Text
                      css={{ fontWeight: "$600", marginBottom: "$4" }}
                      size={"large"}
                    >
                      Popular service & big city
                    </Text>{" "}
                    Redirect to the "Inbox" highlighting the incoming quotes.
                  </Text>
                  <Text as={"li"}>
                    <Text
                      css={{ fontWeight: "$600", marginBottom: "$4" }}
                      size={"large"}
                    >
                      Unpopular service or smaller location:
                    </Text>{" "}
                    Redirect to the "All professionals" (if no quotes received)
                    providing with the listing of available specialists.
                  </Text>
                  <Text as={"li"}>
                    <Text
                      css={{ fontWeight: "$600", marginBottom: "$4" }}
                      size={"large"}
                    >
                      Unpopular service & smaller location
                    </Text>{" "}
                    Redirect to the "All professionals" filled with "Open
                    supply", which is...
                  </Text>
                </ul>
              </Text>
            </Section>

            <Divider direction="horisontal" />

            <Section ref={section5}>
              <h2>..."Open supply"</h2>

              <Text size="large">
                At that moment interactions with the client required a
                subscription and there were enough professionals without one.
                So, we decided to start displaying them to our clients in case
                we lack subscribed professionals (unpopular service & smaller
                location cases from the section above). The initiative was
                called "Open supply". Usually, the profiles were less compelling
                and fulfilled, but this approach appeared to be more efficient
                than loosening the search query.
              </Text>
              <Illustration
                image={data.openSupply}
                alt={`2 "Open Supply" professionals and simplified notification about clients' interest`}
                caption="Clients get access to more professionals, while we can use
                  clients' interest to re-engage more professionals"
              />
              <Text size="large">
                And it allowed us to use the client's interest as leverage to
                attract more professionals into the subscription by having a
                real demand instead of giving promises.
              </Text>
            </Section>

            <Divider direction="horisontal" />

            <Section ref={section6}>
              <h2>Cut the communication starter efforts</h2>
              <Illustration
                image={data.contactPopup}
                alt="A pop-up with a pre-filled message ready to be sent"
              />
              <Text size="large">
                The "Open supply" approach significantly increased the number of
                interactions within the segment but the efficiency was quite
                low. So we started to invite clients to contact more
                professionals to decrease the chances of getting no answer.
              </Text>
              <Text size="large">
                Apart from cheering hints, we introduced a popup with a
                placeholder message (or repeating the last sent) allowing
                clients to declare their interest in professional services in
                just 2 clicks. And it helped by doubling the number of clients
                contacting more than one professional.
              </Text>
              <Text size="large">
                The next step, which is the introduction of bulk messaging, was
                postponed due to switching to the other mission.
              </Text>
            </Section>

            <Divider direction="horisontal" />

            <Section ref={section7}>
              <h2>Other</h2>
              <Text size="large">
                We were experimenting over a bunch of other less prominent
                things — empty states, titles, prioritisation of professionals,
                header. One of the fun things was the fact that decreasing page
                loading time has ruined the conversion — our matching algorithms
                were coming late with options, so every client was redirected to
                the "Open Supply" flow.
              </Text>
            </Section>

            <Divider direction="horisontal" />

            <Section ref={section8}>
              <h2>Results</h2>
              <Text size="large">
                During about half a year I was working on Project Overview page
                as a part of Client funnel optimisation scope. The redesign
                included:
              </Text>
              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$8",
                  paddingBottom: "$24",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint boxed outlined text="Segmentation of clients" />
                <IntroPoint boxed outlined text="Page architecture rebuild" />
                <IntroPoint
                  boxed
                  outlined
                  text="Adjustment of the business model"
                />
                <IntroPoint
                  boxed
                  outlined
                  text="Optimisation of crucial elements and flows"
                />
              </Box>
              <Text size="large">
                As the result, the share of Clients starting communication with
                Professionals increased almost 4 times. This phenominal number
                was partially achived by sacrificing the next funnel step
                performance, but still on the scale of the entire funnel it can
                be estimated as x2.2 growth.
              </Text>

              <Box
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "$8",
                  rowGap: "$16",
                  marginBottom: "$24",

                  "@bp1": {
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <IntroPoint
                  boxed
                  large
                  title="Target page conversion"
                  text="x4 increase"
                />
                <IntroPoint
                  boxed
                  large
                  title="Entire funnel covertion"
                  text="x2.2 increase"
                />
              </Box>
              <Illustration
                image={data.resultsGraph}
                alt="Two graphs showing gradual growth of target metric"
                caption="The target metric progression: cumulative and devided into segments."
              />

              <Text size="large">
                There were still ideas for the further Project Overview page
                enhancements, but it was excluded from the Growth team scope as
                we the entire Client funnel optimisation.
              </Text>
            </Section>

            <Divider direction="horisontal" />

            <Section ref={section8}>
              <Text size="large">Thank you.</Text>
            </Section>
            <BackLink displayOnDesktop={false} />
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default SosProjectOverviewPage

export const pageQuery = graphql`
  query {
    procards: file(relativePath: { eq: "portfolio-images/procards.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cardRecomendationExample: file(
      relativePath: { eq: "portfolio-images/card-recomendation-example.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    openSupply: file(relativePath: { eq: "portfolio-images/open-supply.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    contactPopup: file(
      relativePath: { eq: "portfolio-images/contact-popup.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    resultsGraph: file(
      relativePath: { eq: "portfolio-images/results-graph.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    initialState: file(
      relativePath: { eq: "portfolio-images/initial-state.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cardsExamples: file(
      relativePath: { eq: "portfolio-images/cards-examples.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    architecture: file(
      relativePath: { eq: "portfolio-images/architecture.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    cover: file(
      relativePath: { eq: "portfolio-images/project-overview-cover.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          blurredOptions: { width: 240 }
          width: 1280
          placeholder: BLURRED
        )
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
